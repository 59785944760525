import { jsxs, jsx } from 'react/jsx-runtime';
import { styled, useTheme, CircularProgress, Typography as Typography$1 } from '@mui/material';
import { memo, useRef, useState, useEffect } from 'react';
import { useFileDetails } from '../hooks/useFileDetails.js';
import '../services/itemApi.js';
import { useIcon } from '../hooks/useIcon.js';
import '../services/requestApi.js';
import '../services/userApi.js';
import '../services/requestDetailsApi.js';
import '../auth/config.js';
import '../config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import '../types/requestTypes.js';
import { ItemManagementStatus } from '../types/itemTypes.js';
import 'dayjs';
import 'lodash';
import { useAppDispatch } from '../store/store.js';
import 'react-redux';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import { setCurrentItem } from '../store/slices/itemModalSlice.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';
import { Box } from './Box.js';
import { Alert } from './alert/Alert.js';
import { Open16Regular, Info32Regular } from '@fluentui/react-icons';
import './item/ItemBusinessTypePanel.js';
import { ItemEmptyState } from './item/ItemEmptyState.js';
import './item/ItemGovernancePanel.js';
import '@testing-library/react';
import { lightTheme, palette } from '../themes/light.theme.js';
import { Button } from './button/Button.js';
import './chip/ItemManagementStatusChip.js';
import './chip/PerspectiveClassChip.js';
import { Typography } from './Typography.js';
import './item/type/ItemTypeEmailPanel.js';
import { DocumentPreviewMessage, CaptureContentMessage, OpenItemMessage } from '../constants/messages.js';

const StyledImage = styled('img') ``;
const FileViewer = ({ id, itemDetails, rowData, setPreviewState, selectedSnapshot, onSnapshotOpen, isSnapshotDetail, currentTab }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const viewer = useRef(null);
    const iframeRef = useRef(null);
    const [alertError, setAlertOpen] = useState(false);
    const typeDefImageId = itemDetails?.TypeDef?._ImageId;
    const itemImageId = itemDetails?._ImageId;
    const referenceType = itemDetails?.TypeDef?.Name;
    const isSnapshot = isSnapshotDetail || !!selectedSnapshot;
    const { iconDetails } = useIcon({
        imageId: itemImageId ?? typeDefImageId,
    });
    const { fileDetails, htmlData, docBlobUrl, pdfJsonBlobUrl, error, handleRetrieveContent, userMessage, setUserMessage, isLoading } = useFileDetails({
        id,
        item: itemDetails,
        viewer,
        rowData,
        snapshotVersion: selectedSnapshot?.['RelatedObjId'],
        snapshotContentId: selectedSnapshot?.['ContentId'],
        setAlertOpen,
        isSnapshot
    });
    useEffect(() => {
        !!setPreviewState &&
            setPreviewState(!!fileDetails || !!htmlData || !!docBlobUrl || !!pdfJsonBlobUrl);
    }, [fileDetails, htmlData, docBlobUrl, pdfJsonBlobUrl, id]);
    useEffect(() => {
        if (error && itemDetails.Status !== ItemManagementStatus.Destroyed) {
            setAlertOpen(true);
        }
    }, [error]);
    const handleOpenSnapshot = () => {
        onSnapshotOpen();
        dispatch(setCurrentItem({ id: selectedSnapshot.RelatedObjId, itemId: id, name: selectedSnapshot?.DisplayName, isSnapshot: true, initialTab: 'snapshotDetails' }));
    };
    const renderInfoPanel = () => {
        if (isLoading || (!fileDetails && !htmlData && !docBlobUrl && !pdfJsonBlobUrl && !error && !selectedSnapshot?._Display))
            return null;
        let displayTitle = '';
        if (selectedSnapshot?._Display && currentTab === 'snapshotHistory')
            displayTitle = selectedSnapshot?.DisplayName;
        else if (htmlData && referenceType === 'PostBasedConversation')
            displayTitle = 'Conversation';
        else
            displayTitle = itemDetails._Display;
        return (jsxs(Box, { width: '100%', direction: 'row', alignItems: 'center', background: 'light', style: {
                height: '3rem',
                backgroundColor: theme.palette.background.paper,
                ...currentTab === 'snapshotHistory' && ({
                    justifyContent: 'space-between'
                }),
            }, children: [jsxs("div", { style: { marginLeft: '1.5rem' }, children: [iconDetails && fileDetails ? (jsx(StyledImage, { src: `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`, alt: iconDetails?.AltText, style: { height: '1.5rem' } })) : null, jsx(Typography, { variant: 'h5', children: displayTitle })] }), currentTab === 'snapshotHistory' && (jsx("div", { style: { marginRight: '1.5rem', gap: '0.5rem' }, children: jsx(Button, { startIcon: jsx(Open16Regular, {}), variant: 'text', size: 'medium', sx: { padding: '0.5rem 1rem !important' }, onClick: handleOpenSnapshot, disabled: !selectedSnapshot?._Display, children: "Open Snapshot" }) }))] }));
    };
    const renderContentViewer = () => {
        let documentViewer = '';
        if (isLoading) {
            documentViewer = (jsx("div", { style: { width: '100%', height: '100%', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }, children: jsx(CircularProgress, { color: 'primary', size: '3rem' }) }));
        }
        else if (fileDetails || htmlData || docBlobUrl || pdfJsonBlobUrl) {
            if (fileDetails) {
                documentViewer = (jsx("div", { ref: viewer, style: { height: '100%', width: '100%' } }));
            }
            else if (htmlData) {
                documentViewer = (jsx("iframe", { title: 'Preview', ref: iframeRef, srcDoc: htmlData, style: { width: '100%', height: '100%', border: 'none' } }));
            }
            else if (docBlobUrl) {
                documentViewer = (jsx("div", { ref: viewer, style: { width: '100%', height: '100%' } }));
            }
            else if (pdfJsonBlobUrl) {
                documentViewer = (jsx("iframe", { title: 'Eigen Preview', src: pdfJsonBlobUrl, style: { width: '100%', height: 'calc(100% - 3.5rem)', border: 'none' } }));
            }
        }
        else if (error) {
            documentViewer = (jsxs("div", { style: { width: '100%', height: '100%', border: 'none' }, children: [jsx(Alert, { alertType: 'warning', message: error, open: alertError, onClose: () => {
                            setAlertOpen(false);
                        }, autoHideDuration: 10000 }), jsx(ItemEmptyState, { repositoryUrl: itemDetails.RepositoryUrl })] }));
        }
        else if (userMessage !== '') {
            documentViewer = (jsx("div", { style: { width: '100%', height: '100%', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }, children: jsxs("div", { style: { height: userMessage === DocumentPreviewMessage.MetadataOnly || userMessage === DocumentPreviewMessage.NoContent ? '30rem' : '20rem', width: userMessage === DocumentPreviewMessage.MetadataOnly ? '40rem' : '30rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }, children: [userMessage === DocumentPreviewMessage.IsRetrievingContent && (jsxs("div", { style: { paddingBottom: "1.25rem", display: "flex", flexDirection: "column", alignItems: "center" }, children: [jsx(CircularProgress, { color: "primary", size: "3rem" }), jsx(Typography, { variant: "h4", color: lightTheme.palette.text.primary, sx: {
                                        paddingTop: "2rem",
                                        paddingLeft: "5rem",
                                        paddingRight: "5rem",
                                        wordBreak: "break-word",
                                        "WebkitLineClamp": "none",
                                        textAlign: "center"
                                    }, children: userMessage })] })), userMessage !== DocumentPreviewMessage.IsRetrievingContent && (jsx(Info32Regular, { style: { paddingBottom: "1.25rem", minHeight: "2rem" }, color: userMessage === DocumentPreviewMessage.FailedRetrieval || userMessage === DocumentPreviewMessage.LongRetrieval ? '#A94441' : lightTheme.palette.primary.main })), userMessage !== DocumentPreviewMessage.IsRetrievingContent && (jsx(Typography, { variant: "h3", color: lightTheme.palette.text.primary, sx: {
                                paddingBottom: "1.25rem",
                                wordBreak: "break-word",
                                textAlign: "center",
                                "-webkit-line-clamp": "unset"
                            }, children: userMessage })), userMessage !== DocumentPreviewMessage.IsRetrievingContent && userMessage !== DocumentPreviewMessage.FailedRetrieval && (jsxs("div", { style: { display: "flex", flexDirection: "row", height: "fit-content", padding: "1rem 3rem", width: "100%" }, children: [userMessage === DocumentPreviewMessage.MetadataOnly && (jsxs("div", { style: { display: "flex", flexDirection: "column", padding: "1rem", alignItems: "center", justifyContent: "space-between", width: "100%" }, children: [jsx(Typography, { variant: "h3", color: lightTheme.palette.text.primary, sx: {
                                                wordBreak: "break-word",
                                                "WebkitLineClamp": "none",
                                                textAlign: "center"
                                            }, children: CaptureContentMessage.Title }), jsx(Typography, { variant: "h5", color: lightTheme.palette.text.primary, sx: {
                                                padding: "1rem",
                                                wordBreak: "break-word",
                                                "WebkitLineClamp": "none",
                                                textAlign: "center",
                                                fontFamily: "Avenir Roman"
                                            }, children: CaptureContentMessage.MainMessage }), jsx(Button, { variant: "contained", sx: { textTransform: "none", marginTop: "1rem" }, onClick: handleRetrieveContent, children: jsx(Typography, { variant: "subtitle1", color: lightTheme.palette.common.white, children: CaptureContentMessage.ButtonTitle }) })] })), !!itemDetails.RepositoryUrl && (jsxs("div", { style: { display: "flex", flexDirection: "column", borderLeft: `${userMessage === DocumentPreviewMessage.MetadataOnly ? 1 : 0}px solid rgba(104, 113, 134, 0.20)`, padding: "1rem", alignItems: "center", justifyContent: "space-between", width: "100%" }, children: [jsx(Typography, { variant: "h3", color: lightTheme.palette.text.primary, sx: {
                                                wordBreak: "break-word",
                                                "WebkitLineClamp": "none",
                                                textAlign: "center"
                                            }, children: OpenItemMessage.Title }), jsx(Typography, { variant: "h5", color: lightTheme.palette.text.primary, sx: {
                                                padding: "1rem",
                                                wordBreak: "break-word",
                                                "WebkitLineClamp": "none",
                                                textAlign: "center",
                                                fontFamily: "Avenir Roman"
                                            }, children: OpenItemMessage.MainMessage }), jsx(Typography, { variant: 'body1', sx: {
                                                width: '18.188rem',
                                                textAlign: 'center',
                                                lineHeight: '2.5rem',
                                                marginTop: "1rem"
                                            }, children: jsx(Typography$1, { component: 'a', variant: 'body1', href: itemDetails.RepositoryUrl, target: '_blank', sx: {
                                                    color: palette.aqua[800],
                                                    textDecoration: 'none',
                                                }, children: OpenItemMessage.ButtonTitle }) }, 'no-item-desc')] }))] })), userMessage === DocumentPreviewMessage.FailedRetrieval && (jsxs("div", { style: { display: "flex", flexDirection: "row" }, children: [jsx(Button, { variant: "contained", sx: { textTransform: "none", marginBottom: "1rem", marginRight: "1rem" }, children: jsx(Typography, { variant: "subtitle1", color: lightTheme.palette.common.white, children: "Try again" }) }), jsx(Button, { variant: "contained", sx: { textTransform: "none", marginBottom: "1rem" }, onClick: () => setUserMessage(DocumentPreviewMessage.MetadataOnly), children: jsx(Typography, { variant: "subtitle1", color: lightTheme.palette.common.white, children: "Cancel" }) })] }))] }) }));
        }
        else {
            documentViewer = '';
        }
        return documentViewer;
    };
    return (jsxs("div", { style: { width: '100%', height: '100%', border: 'none', position: 'relative' }, children: [renderInfoPanel(), renderContentViewer()] }));
};
const MemoizedFileViewer = memo(FileViewer);

export { FileViewer, MemoizedFileViewer };
